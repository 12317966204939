import React from 'react';

const Diabetes = ({determinationWithControl, results, msgPathology}) => {
    return (
        <>
            {determinationWithControl ? (
                <>
                    <tr>
                        <td>Hemoglobina glicosilada</td>
                        <td>{results.happ.HbA1C}</td>
                        {determinationWithControl ? (
                            <td>{results.meta.HbA1C}</td>
                        ) : null}
                        <td>Menor a 5.7 %</td>
                        <td>{msgPathology}</td>
                    </tr>
                </>
            ) : (
                <tr>
                    <td>Hemoglucotest</td>
                    <td>{results.happ.glicemia}</td>
                    <td>Menor a 110 mg/dl</td>
                    <td>{msgPathology}</td>
                </tr>
            )}
        </>
    );
}
 
export default Diabetes;