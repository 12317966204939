import { Whatsapp } from 'react-bootstrap-icons';
import './whatsapp.css';

const handleClick = () => {
    const link = document.createElement('a');
    link.href = "https://wa.me/56933006005";
    link.target = '_blank';
    link.rel = 'noopener noreferrer'; // Seguridad adicional
    link.click();

}

const WhatsAppButtonIcon = () => {
    return (
        <div 
            className='ws-content'
            onClick={handleClick}
        >
            <Whatsapp />
      </div> 
    );
}
 
export default WhatsAppButtonIcon;