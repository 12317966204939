import { GreenWeightBar } from 'happ-icons/icons'
import { HighWeighBar } from 'happ-icons/icons'
import { HighHemoglucotest } from 'happ-icons/icons'
import { NormalHemoglucotest } from 'happ-icons/icons'
import { HighSystolic } from 'happ-icons/icons'
import { Normalystolic } from 'happ-icons/icons'
import { LowWeightBar } from 'happ-icons/icons'
import { MediumHemoglucotest } from 'happ-icons/icons'
import mildDiastolic from './MildDiastolic.svg';
import mildSystolic from './MildSystolic.svg';
import normalDiastolic from './NormalDiastolic.svg';
import highDiastolic from './HighDiastolic.svg';
import highSystolic from './HighSystolic.svg';
import normalSystolic from './NormalSystolic.svg';
import React from 'react'
import { HighDiastolic, NormalDiastolic, ObesityIandII, OverWeightBar } from '../../happ-icons/icons'

const BarInfoContentLC = ({ diagnosisContent }) => {

    // colesterol pre-diabetes obesidad hipertension

    const { color, status, results } = diagnosisContent;
    // console.log(status)
    // console.log(color)
    console.log(results[0])
    console.log(results[0].sistolica)
    console.log(results[0].diastolica)
    return (
        <>
            {(diagnosisContent.code === 'diabetes' || diagnosisContent.code === 'pre-diabetes') && results[0].fast ? (
                color === 'success' ? (
                    <NormalHemoglucotest className='bar-table-modal' />
                ) : color === 'warning' ? (
                    <MediumHemoglucotest className='bar-table-modal' />
                ) :
                    <HighHemoglucotest className='bar-table-modal' />
            ) : null}

            {diagnosisContent.code === 'obesidad' ? (
                status === 'Saludable' ? (
                    <GreenWeightBar
                        fill="white"
                        responsive="scale-mobile"
                    />
                ) : status === 'Sobrepeso' ?
                    <OverWeightBar
                        fill="white"
                        responsive="scale-mobile"
                    />
                    : status === 'Obesidad I' || status === 'Obesidad II' ?
                        <ObesityIandII
                            fill="white"
                            responsive="scale-mobile"
                        />
                        : status === 'Obesidad III' ?
                            <HighWeighBar
                                fill="white"
                                responsive="scale-mobile"
                            />
                            : status === 'Bajo peso' ?
                                <LowWeightBar
                                    fill="white"
                                    responsive="scale-mobile"
                                />
                                : null)
                : null}

            {diagnosisContent.code === 'hipertension' ? (
                <div className='d-flex flex-column content-bar-bp'>
                    <>
                        {results.results.sistolica <= 129  ? (
                            <img src={normalSystolic} />
                        ) : results.results.sistolica >= 130 && results.results.sistolica <= 139 ? (
                            <img src={mildSystolic} />
                        ) : results.results.sistolica >= 140 ? (
                            <img src={highSystolic} />
                        ) : null}
                        {results.results.diastolica <= 79 ? (
                            <img src={normalDiastolic} />
                        ) : results.results.diastolica >= 80 && results.results.diastolica <= 89 ? (
                            <img src={mildDiastolic} />
                        ) : results.results.diastolica >= 90 ? (
                            <img src={highDiastolic} />
                        ) : null} 
                    </>
                </div>
            ) : null}
        </>
    )
}

export default BarInfoContentLC